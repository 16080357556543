.trof-hotels__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  list-style: none;
  padding: 0;
}

.trof-hotels__text {
  margin: 10px 0;
}

.trof-hotel {
  display: flex;
  padding: 20px;
  background: rgba(0, 0, 0, 0.5);
  border-radius: 30px;
}

.trof-hotel__text-container {
  width: 67%;
  padding: 10px 50px 10px 25px;
  display: flex;
  flex-direction: column;
  gap: 15px;
  position: relative;
}

.trof-hotel__rating-container {
  display: flex;
  gap: 20px;
}

.trof-hotel__small-container {
  display: flex;
  align-items: center;
  gap: 10px;
}

.trof-hotel__img-container {
  width: 33%;
  overflow: hidden;
  border-radius: 30px 0 0 30px;
  min-width: 330px;
}

.trof-hotel__img {
  height: 100%;
  width: auto;
}

.trof-hotel__reserve {
  width: 200px;
  height: 50px;
  background-color: #ffc107;
  color: #ff4d00;
  border-radius: 10px;
  text-transform: uppercase;
  display: flex;
  justify-content: center;
  align-items: center;
  transition: all 0.3s;
  margin-top: auto;
  font-weight: 800;
  align-self: end;
}

.trof-hotel__reserve:hover {
  background-color: #ff4d00;
  color: #ffc107;
}

.trof-hotel__arrow {
  margin-left: 10px;
}

@media (max-width: 1100px) {
}

@media (max-width: 749px) {
  .trof-hotel {
    flex-direction: column;
    padding: 0;
  }

  .trof-hotel__img-container,
  .trof-hotel__text-container {
    width: 100%;
    border-radius: 30px;
  }

  .trof-hotel__text-container {
    padding: 10px;
  }

  .trof-hotel__img-container {
    min-width: 100%;
  }

  .trof-hotel__img {
    width: 100%;
    height: 100%;
  }

  .trof-hotel__reserve {
    align-self: center;
  }
}

@media (max-width: 430px) {
}
