.trof-cover {
  background: url("../../images/main-cover.webp") center / cover no-repeat;
  min-height: 600px;
  margin: -70px auto 0;
  display: flex;
  align-items: center;
  padding: 0 60px;
  border-radius: 0 0 30px 30px;
}

.trof-direction-page-cover {
  justify-content: center;
}

.trof-cover__overlay {
  display: flex;
  justify-content: center;
  align-items: start;
  flex-direction: column;
  position: relative;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 30px;
  max-width: 900px;
  gap: 20px;
}

@media (max-width: 1100px) {
  .trof-cover {
    padding: 50px 20px;
  }
}

@media (max-width: 749px) {
  .trof-cover {
    min-height: 450px;
    margin-top: -50px;
    background: url("../../images/main-cover-mob.webp") center / cover no-repeat;
  }
}
