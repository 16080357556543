.trof-direction__pages, .trof-hotels__container {
  display: flex;
  flex-direction: column;
  gap: 20px;
}

.page-one {
  background: url('/src/images/turkey-main.webp') center / cover no-repeat;
}

.page-two {
  background: url('/src/images/denmark-main.webp') center / cover no-repeat;
}

.page-three {
  background: url('/src/images/holland-main.webp') center / cover no-repeat;
}