.trof-popup {
  position: fixed;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  background-color: rgba(26, 26, 26, 0.8);
  display: flex;
  align-items: start;
  justify-content: center;
  z-index: 9999;
  padding: 25px;
  overflow: auto;
}

.trof-popup-cookie {
  position: fixed;
  bottom: 0;
  right: 0;
  width: 100%;
  max-width: 532px;
  min-height: 327px;
  z-index: 3;
  background: #ffc107;
  padding: 20px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.trof-popup__body {
  width: 100%;
  max-width: 532px;
  min-height: 327px;
  position: relative;
  background: #ffc107;
  padding: 20px 30px;
  display: flex;
  justify-content: center;
  align-items: center;
  border-radius: 30px;
}

.trof-popup__form {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  gap: 20px;
}

.trof-popup__header {
  font-size: 31px;
  line-height: 43px;
  font-weight: 700;
  color: #ff4d00;
}

.trof-popup__btn {
  width: 100%;
  max-width: 193px;
  height: 58px;
  cursor: pointer;
  background: #ff4d00;
  border: none;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  text-transform: uppercase;
}

.trof-popup__btn-container {
  display: flex;
  width: 100%;
  gap: 20px;
  justify-content: space-between;
}

.trof-popup__btn-cookie {
  width: 100%;
  height: 40px;
  height: 58px;
  cursor: pointer;
  background: #ff4d00;
  border: none;
  text-align: center;
  border-radius: 20px;
  color: #fff;
  display: flex;
  justify-content: center;
  align-items: center;
}

.trof-popup__btn-agree {
  background-color: #fff;
  border: 2px solid #ff4d00;
  color: #000;
}

.disabled {
  display: none;
}

@media (max-width: 749px) {
  .trof-popup__header {
    font-size: 21px;
    line-height: 33px;
  }
}

@media (max-width: 480px) {
  .trof-popup__btn-container {
    flex-direction: column;
    align-items: center;
  }
}
