.trof-directions {
  display: grid;
  grid-template-columns: 1fr 1.3fr;
  gap: 20px;
}

.trof-directions-popup {
  display: flex;
  flex-direction: column;
  gap: 20px;
  max-width: 760px;
  position: relative;
}

.trof-directions__text {
  display: flex;
  flex-direction: column;
  gap: 20px;
  background-color: rgba(0, 0, 0, 0.5);
  padding: 30px;
  border-radius: 30px;
}

.trof-directions__link-container {
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-template-rows: 1fr 1fr;
  gap: 20px;
}

.trof-directions__link {
  position: relative;
}

.trof-directions__overlay {
  background-color: rgba(0, 0, 0, 0.5);
  opacity: 0;
  width: 100%;
  height: 100%;
  top: 0;
  left: 0;
  border-radius: 30px;
  display: flex;
  align-items: center;
  justify-content: center;
  position: absolute;
  transition: all 0.3s;
  padding: 20px;
}

.trof-directions__overlay:hover {
  opacity: 1;
}

.trof-directions__overlay-text {
  text-transform: uppercase;
  font-weight: 800;
  text-align: center;
}

.grid-two {
  grid-column: 2;
  grid-row: 1 / span 2;
}

.trof-directions__img {
  width: 100%;
  height: 100%;
  object-fit: cover;
  border-radius: 30px;
}

.trof-directions__close {
  position: absolute;
  top: 15px;
  right: 15px;
  cursor: pointer;
}

@media (max-width: 1100px) {
  .trof-directions,
  .trof-directions-popup, .trof-directions__link-container {
    gap: 10px;
  }
}

@media (max-width: 749px) {
  .trof-directions {
    display: flex;
    flex-direction: column;
  }

  .trof-directions__overlay {
    opacity: 1;
  }

  .trof-directions__overlay-text {
    font-size: 12px;
    line-height: 16px;
  }
}
