@import url("/src/vendor/normalize.css");
@import url("/src/vendor/fonts/fonts.css");

* {
  box-sizing: border-box;
}

html {
  background-color: #879f93;
}

.page {
  max-width: 1440px;
  margin: 0 auto;
  font-family: "Inter", "Arial", sans-serif;
  display: flex;
  flex-direction: column;
  text-align: left;
}

p,
a,
li {
  margin: 0;
  font-weight: 500;
  color: #fff;
  text-decoration: none;
}

h1,
h2 {
  font-size: 48px;
  line-height: 58px;
  font-weight: 900;
  margin: 0;
  color: #ffc107;
  text-transform: uppercase;
}

.t-32 {
  font-size: 32px;
  line-height: 39px;
  font-weight: 700;
  margin: 0;
}

.t-24 {
  font-size: 24px;
  line-height: 30px;
}

.t-20 {
  font-size: 20px;
  line-height: 27px;
}

.t-16 {
  font-size: 16px;
  line-height: 20px;
}

.col-yellow {
  color: #ffc107;
}

.col-black {
  color: #000;
}

.text-center {
  text-align: center;
}

.pad {
  padding: 70px 60px 0;
}

.trof-privacy {
  display: flex;
  flex-direction: column;
  gap: 15px;
}

.trof-privacy h1,
.trof-privacy h2 {
  font-size: 35px;
  line-height: 42px;
}

@media (max-width: 1100px) {
  h1,
  h2 {
    font-size: 35px;
    line-height: 42px;
  }

  .pad {
    padding: 40px 20px 0;
  }

  .t-24 {
    font-size: 20px;
    line-height: 25px;
  }

  .t-20 {
    font-size: 16px;
    line-height: 22px;
  }
}

@media (max-width: 749px) {
  h1,
  h2,
  .trof-privacy h1,
  .trof-privacy h2 {
    font-size: 30px;
    line-height: 35px;
  }
}
