.footer {
  display: grid;
  grid-template-columns: 1fr 1fr;
  background-color: #ffc107;
  border-radius: 30px 30px 0 0;
  padding: 45px 60px;
  margin-top: 70px;
}

.footer__el1 {
  display: flex;
  flex-direction: column;
  gap: 30px;
  margin-right: 60px;
  margin-left: 30px;
}

.footer__el2 {
  display: flex;
  flex-direction: column;
  justify-content: space-between;
  margin-left: 30px;
}

.footer__adress {
  display: flex;
  gap: 20px;
  align-items: center;
}


.footer__cookie-header {
  font-size: 20px;
  line-height: 24px;
  font-weight: 700;
  text-transform: uppercase;
  color: #ff4d00;
}

.footer__span {
  text-decoration: underline;
}

.footer a {
  width: fit-content;
  color: #ff4d00;
  font-weight: 700;
}

.footer__email {
  font-weight: 500;
}

@media (max-width: 1100px) {
  .footer {
    padding: 45px 50px;
  }
}

@media (max-width: 749px) {
  .footer {
    display: flex;
    flex-direction: column;
    gap: 20px;
    padding: 45px 20px;
  }

  .footer__el1 {
    margin: 0;
  }

  .footer__el2 {
    gap: 20px;
    margin: 0;
  }

  .footer__el3 {
    align-items: start;
    gap: 20px;
  }
}
