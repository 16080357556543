.header__container {
  width: 100%;
  height: 70px;
  display: flex;
  justify-content: center;
  align-items: center;
  padding: 0 20px;
}

.header__link-container {
  height: 35px;
  background-color: rgba(0, 0, 0, 0.5);
  display: flex;
  align-items: center;
  justify-content: space-between;
  gap: 30px;
  position: relative;
  z-index: 5;
  border-radius: 30px;
  padding: 10px 24px;
}
.header__link {
  color: #ffffff;
  text-decoration: none;
  font-size: 15px;
  line-height: 18px;
  cursor: pointer;
  text-transform: capitalize;
  transition: all 0.3s;
}

.header__link:hover {
  color: #ffc107;
}

.burger-menu {
  display: none;
  cursor: pointer;
  z-index: 2;
}

.burger__close-btn {
  display: none;
}

.burger-menu__nav {
  position: fixed;
  top: 0;
  left: -100%;
  width: 100%;
  height: 100%;
  background: #879f93;
  transition: left 0.3s ease-in-out;
  display: flex;
  align-items: center;
  justify-content: center;
  z-index: 9999;
}

.burger-menu__nav.active {
  left: 0;
}

.burger-menu__nav-container {
  display: flex;
  flex-direction: column;
  gap: 20px;
  align-items: center;
}

.burger-menu__nav-link {
  margin: 10px 0 20px;
}

.burger__close-btn {
  position: absolute;
  top: 20px;
  right: 20px;
  display: none;
  cursor: pointer;
}

.burger-menu__nav.active .burger__close-btn {
  display: block;
}

.burger-menu.active #burger-icon {
  display: none;
}

@media (max-width: 749px) {
  .burger-menu {
    display: flex;
  }

  .header__link-container {
    display: none;
  }

  .header__link {
    font-size: 17px;
    color: #ffc107;
  }

  .header__container {
    height: 50px;
    justify-content: start;
  }
}
