.trof-reviews__item {
  background: rgba(0, 0, 0, 0.5);
  padding: 20px;
  border: 2px solid #ffc107;
  display: flex;
  flex-direction: column;
  gap: 15px;
  border-radius: 20px;
  height: auto;
}

.trof-review__swiper {
  margin-top: 20px;
  padding-bottom: 60px;
}

.trof-review__name {
  font-weight: 700;
  color: #ff4d00;
  display: flex;
  gap: 20px;
  align-items: start;
}

.trof-reviews__img {
  border-radius: 20px;
}

.swiper-pagination-bullet {
  background-color: #ff4d00;
}

.swiper-pagination-bullet-active {
  background-color: #ff4d00;
}

.swiper-pagination {
  z-index: 1;
}

.swiper-button-next {
  width: 40px;
  height: 40px;
  background: url("/src/images/next.svg") center / contain no-repeat;
  position: absolute;
  margin-left: 45px;
  left: 50%;
  z-index: 2;
  bottom: 0;
  cursor: pointer;
}

.swiper-button-prev {
  width: 40px;
  height: 40px;
  background: url("/src/images/prev.svg") center / contain no-repeat;
  position: absolute;
  margin-right: 45px;
  right: 50%;
  z-index: 2;
  bottom: 0;
  cursor: pointer;
}

@media (max-width: 749px) {
  .swiper-button-next,
  .swiper-button-prev {
    display: none;
  }

  .trof-review__swiper {
    padding-bottom: 40px;
  }
}
